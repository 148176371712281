<script setup lang="ts">
const localePath = useLocalePath()
const { createOfferPath } = useCreateOffer()
</script>

<template>
	<UEmptyState
		:title="$t('Sin búsquedas')"
		class="space-y-5"
	>
		<template #graphic>
			<div class="inline-flex items-center justify-center bg-neutral-500 bg-opacity-5 rounded-full size-44">
				<img
					:src="`${$config.public.cdn}/empty-search_1721369538664.svg`"
					alt="Search"
					class="size-28 block relative -mt-2"
				/>
			</div>
		</template>

		<div>
			<p class="opacity-70 text-sm -mt-3">
				{{ $t('Configura una nueva búsqueda para recibir candidatos que se ajusten a tus necesidades.') }}
			</p>
		</div>

		<UButton
			variant="outline"
			icon="i-material-symbols-add"
			:to="localePath(createOfferPath)"
		>
			{{ $t('Crear búsqueda') }}
		</UButton>
	</UEmptyState>
</template>

<style scoped></style>
