<script setup lang="ts">
import { useOffersStore } from '~/store/offers'

const loadingDashboard = ref(true)
const { fetchStatsOffers } = useOffersStore()
const { stats } = storeToRefs(useOffersStore())

const getStats = async () => {
	loadingDashboard.value = true
	await fetchStatsOffers()
	loadingDashboard.value = false
}

onMounted(() => {
	getStats()
})
</script>

<template>
	<div class="grid grid-cols-2 lg:grid-cols-4 gap-4">
		<div class="border-r">
			<div class="text-xs font-light mb-1">{{ $t('Total de ofertas') }}</div>
			<div class="text-xl leading-none font-semibold">
				<USkeleton
					v-if="loadingDashboard"
					class="w-5"
					:ui="{ rounded: 'rounded-full' }"
				/>
				<span v-else> {{ stats?.registered_offers ?? 0 }}</span>
			</div>
		</div>

		<div class="lg:border-r">
			<div class="text-xs font-light mb-1">{{ $t('Ofertas activas') }}</div>
			<div class="text-xl leading-none font-semibold">
				<USkeleton
					v-if="loadingDashboard"
					class="w-5"
					:ui="{ rounded: 'rounded-full' }"
				/>
				<span v-else>{{ stats?.active_offers ?? 0 }}</span>
			</div>
		</div>

		<div class="border-r">
			<div class="text-xs font-light mb-1">{{ $t('Candidatos en proceso') }}</div>
			<div class="text-xl leading-none font-semibold">
				<USkeleton
					v-if="loadingDashboard"
					class="w-5"
					:ui="{ rounded: 'rounded-full' }"
				/>
				<span v-else>{{ stats?.in_process_candidates ?? 0 }}</span>
			</div>
		</div>

		<div>
			<div class="text-xs font-light mb-1">{{ $t('Total de contrataciones') }}</div>
			<div class="text-xl leading-none font-semibold">
				<USkeleton
					v-if="loadingDashboard"
					class="w-5"
					:ui="{ rounded: 'rounded-full' }"
				/>
				<span v-else>{{ stats?.hired_candidates ?? 0 }}</span>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
