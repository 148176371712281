<script setup lang="ts">
import { useOffersStore } from '~/store/offers'

const { fetchFiltersOffers } = useOffersStore()
const { listFilter, defaultStatusSlugs } = storeToRefs(useOffersStore())
const { t: $t } = useI18n()
const route = useRoute()
const router = useRouter()

const loadingFilter = ref(true)
const selectedFilter = ref([])

const optionsFilter = computed(() => {
	const list = listFilter.value.map((item) => ({
		label: item.label,
		value: item.slug,
		total: item.total,
	}))

	const optionAll = [
		{
			label: $t('Mostrar todo'),
			slot: 'all',
			total: list.reduce((acc, item) => acc + item.total, 0),
		},
	]

	return [optionAll, list]
})

const isAllSelected = computed(() => selectedFilter.value.length === optionsFilter.value[1].length)

const getLabel = computed(() => {
	const selected = optionsFilter.value[1].filter((item) => selectedFilter.value.includes(item.value))

	if (selected.length === optionsFilter.value[1].length) {
		return $t('Mostrar todo')
	}

	if (selected.length === 1) {
		return selected[0].label
	}

	if (selected.length === 0) {
		return $t('Mostrar todo')
	}

	return `${selected.length} ${$t('Filtros')}`
})

const changeAll = (event) => {
	if (event.target.checked) {
		selectedFilter.value = optionsFilter.value[1].map((item) => item.value)
	}

	if (!event.target.checked) {
		selectedFilter.value = []
	}

	router.push({ query: { status_slugs: selectedFilter.value } })
}

const changeFilter = () => {
	router.push({ query: { status_slugs: selectedFilter.value } })
}

const setFilter = () => {
	const { status_slugs } = route.query

	if (status_slugs) {
		selectedFilter.value = Array.isArray(status_slugs) ? status_slugs : [status_slugs]
	} else {
		selectedFilter.value = defaultStatusSlugs
	}
}

onMounted(async () => {
	loadingFilter.value = true
	await fetchFiltersOffers()
	setFilter()
	loadingFilter.value = false
})
</script>

<template>
	<UDropdown
		:items="optionsFilter"
		:close-delay="1000"
		:ui="{ item: { padding: 'p-0' } }"
	>
		<UButton variant="ghost">
			<span class="font-semibold">{{ getLabel }}</span>

			<UIcon
				name="ic:outline-expand-more"
				dynamic
				size="22"
			/>
		</UButton>

		<template #all="{ item }">
			<label
				class="flex p-1.5 w-full"
				@click.stop
			>
				<UCheckbox
					:model-value="isAllSelected"
					:disabled="loadingFilter"
					:ui="{ wrapper: 'mb-0' }"
					@change="changeAll"
				/>

				<span class="pl-2 flex-1 flex items-center justify-between">
					{{ item.label }}

					<UBadge
						size="xs"
						color="gray"
						variant="soft"
						:ui="{ rounded: 'rounded' }"
					>
						{{ item.total }}
					</UBadge>
				</span>
			</label>
		</template>

		<template #item="{ item }">
			<label
				class="flex p-1.5 w-full"
				@click.stop
			>
				<UCheckbox
					v-model="selectedFilter"
					:value="item.value"
					:disabled="loadingFilter"
					:ui="{ wrapper: 'mb-0' }"
					@change="changeFilter"
				/>

				<span class="pl-2 flex-1 flex items-center justify-between">
					{{ item.label }}

					<UBadge
						size="xs"
						color="gray"
						variant="soft"
						:ui="{ rounded: 'rounded' }"
					>
						{{ item.total }}
					</UBadge>
				</span>
			</label>
		</template>
	</UDropdown>
</template>

<style scoped></style>
