<script setup>
const emit = defineEmits(['cancel', 'submit-success'])

const model = defineModel()

const { $repository } = useNuxtApp()
const { t: $t } = useI18n()

const phone = ref('')
const countryCode = ref('')
const phoneValid = ref(false)
const errors = ref({
	phone: '',
	server: '',
})
const loading = ref(false)

const validatePhone = (val) => {
	phoneValid.value = val
}

const phoneValidate = (phoneObject) => {
	if (phoneObject.valid !== undefined) {
		errors.value.phone = phoneObject.valid ? '' : $t('Número de teléfono inválido')
	}
}

async function togglePhoneSent() {
	errors.value.phone = ''
	errors.value.server = ''

	if (!phoneValid.value) {
		errors.value.phone = $t('Número de teléfono inválido')

		nextTick(() => {
			document.querySelector('#phone').focus()
		})

		return
	}

	loading.value = true
	const response = await $repository.v3.hire.postScheduleCall({
		phone: phone.value,
		country_code: countryCode.value,
		utm_source: 'source',
		utm_medium: 'medium',
		utm_campaign: 'campaign',
		utm_content: 'content',
		utm_term: 'term',
	})
	loading.value = false

	if (!response.success) {
		errors.value.server = response.message
		return
	}

	emit('submit-success')
}
</script>

<template>
	<UModal
		v-model="model"
		:title="''"
		hidden-footer
	>
		<form @submit.prevent="togglePhoneSent">
			<div class="space-y-4">
				<div class="text-sm">
					<p class="mb-1">
						{{ $t('Queremos ayudarte con tu búsqueda') }}
					</p>

					<p class="mb-5 font-bold text-lg">
						{{ $t('Impulsa tu contratación de Talento') }}
					</p>

					<p>{{ $t('Registra tu número, te contactaremos para una asesoría personalizada.') }}</p>
				</div>

				<div>
					<UInputPhone
						id="phone"
						v-model:phone="phone"
						v-model:country="countryCode"
						@validate="validatePhone"
						@blur="phoneValidate"
						@country-changed="phoneValidate"
					/>
					<div
						v-if="errors.phone"
						class="text-sm text-red-500 mt-1.5"
					>
						{{ errors.phone }}
					</div>
				</div>

				<UAlert
					v-if="errors.server"
					color="red"
					icon="i-ic-round-warning-amber"
					title="Error"
					variant="soft"
					:description="errors.server"
				/>

				<div class="text-right">
					<UButton
						:loading="loading"
						type="submit"
					>
						{{ $t('Recibir una llamada') }}
					</UButton>
					<span class="text-xs text-gray-500 block pt-2">
						{{ $t('Un asesor te contactará en el transcurso del día') }}
					</span>
				</div>
			</div>
		</form>
	</UModal>
</template>

<style>
[data-headlessui-state='open'] {
	overflow: visible;
}
</style>
