<script setup lang="ts">
import DashboardCount from '~/components/home/DashboardCount.vue'
import CardOffer from '~/components/offer/CardOfferOld.vue'
import FiltersOffers from '~/components/home/FiltersOffers.vue'
import SkeletonCardOffer from '~/components/offer/SkeletonCardOffer.vue'
import EmptyStateOffers from '~/components/home/EmptyStateOffersOld.vue'
import EmptyStateFiltersOffers from '~/components/home/EmptyStateFiltersOffers.vue'
import CardOfferAdd from '~/components/offer/CardOfferAdd.vue'
import { useOffersStore } from '~/store/offers'

const { fetchOffers, defaultStatusSlugs } = useOffersStore()
const { offers, stats } = storeToRefs(useOffersStore())
const router = useRouter()
const route = useRoute()

const loadingOffers = ref(true)

const isLastPage = computed(() => {
	return offers.value.pagination.current_page === offers.value.pagination.total_pages
})

const fetchData = async (params) => {
	loadingOffers.value = true
	const { status_slugs } = params

	if (status_slugs) {
		params.status_slugs = Array.isArray(status_slugs) ? status_slugs : [status_slugs]
	} else {
		params.status_slugs = defaultStatusSlugs
	}

	await fetchOffers(params)
	loadingOffers.value = false
}

const changePage = (page) => {
	const query = { ...route.query, page }
	router.push({ query })
}

watch(
	() => route.query,
	(newQuery, oldQuery) => {
		if (newQuery.page !== oldQuery.page || newQuery.status_slugs !== oldQuery.status_slugs) {
			fetchData(newQuery)
		}
	},
)

onMounted(() => {
	fetchData(route.query)
})
</script>

<template>
	<div class="grid gap-6">
		<UCard>
			<div class="flex flex-col xl:flex-row xl:items-center flex-wrap gap-4 mb-6">
				<h2 class="text-xl font-semibold">{{ $t('Tus búsquedas') }}</h2>

				<div class="xl:ml-auto">
					<FiltersOffers />
				</div>
			</div>

			<DashboardCount />
		</UCard>

		<div>
			<ul class="grid md:grid-cols-2 3xl:grid-cols-3 gap-5">
				<template v-if="loadingOffers">
					<li
						v-for="item in 6"
						:key="item"
					>
						<SkeletonCardOffer />
					</li>
				</template>

				<template v-else>
					<template v-if="offers.data.length">
						<li
							v-for="offer in offers.data"
							:key="offer.id"
						>
							<CardOffer :offer="offer" />
						</li>
						<li v-if="isLastPage">
							<CardOfferAdd />
						</li>
					</template>

					<template v-else>
						<li class="md:col-span-2 3xl:col-span-3">
							<div class="min-h-[575px] flex items-center justify-center">
								<EmptyStateFiltersOffers
									v-if="stats?.registered_offers"
									class="max-w-md mx-auto"
								/>
								<EmptyStateOffers
									v-else
									class="max-w-md mx-auto"
								/>
							</div>
						</li>
					</template>
				</template>
			</ul>

			<UPagination
				v-if="offers.pagination.total_pages > 1"
				v-model="offers.pagination.current_page"
				class="justify-center mt-6"
				:total="offers.pagination.total"
				:page-count="offers.pagination.per_page"
				@update:model-value="changePage"
			/>
		</div>
	</div>
</template>

<style scoped></style>
