<script setup lang="ts">
import { computed } from 'vue'

interface Props {
	prefix?: string // Texto antes del nombre (ej: "Bienvenido")
	tag?: string // Tag HTML a usar (h1, h2, span, etc)
	icon?: string // Emoji o icono antes del nombre
	iconPosition?: 'before' | 'after' // Posición del icono
	fullName?: boolean // true = nombre completo, false = solo primer nombre
}

const props = withDefaults(defineProps<Props>(), {
	prefix: '',
	tag: 'span',
	icon: '',
	iconPosition: 'before',
	fullName: true, // Por defecto muestra nombre completo
})

const { $auth } = useNuxtApp()

const getName = computed(() => {
	if (!props.fullName && $auth.user.name) {
		return $auth.user.name
	}

	if ($auth.user.name || $auth.user.last_name) {
		return `${$auth.user.name} ${$auth.user.last_name}`
	}

	return $auth.user.business.name
})

const fullText = computed(() => {
	const name = getName.value
	const prefix = props.prefix ? `${props.prefix} ` : ''

	if (!props.icon) return `${prefix}${name}`

	return props.iconPosition === 'before' ? `${prefix}${props.icon} ${name}` : `${prefix}${name} ${props.icon}`
})
</script>

<template>
	<component
		:is="tag"
		class="user-name"
	>
		{{ fullText }}
	</component>
</template>

<style scoped>
.user-name {
	display: inline-flex;
	align-items: center;
	gap: 0.5rem;
}
</style>
