<script setup lang="ts">
const { $repository, $auth } = useNuxtApp()

const loadingSendEmail = ref(false)
const forwardedEmail = ref(false)
const errorMessage = ref('')

const { t: $t } = useI18n()

const resendEmail = async () => {
	errorMessage.value = ''

	loadingSendEmail.value = true

	const response = await $repository.v3.hire.postSendActivationMail({
		email: $auth.user.email,
	})

	if (!response.success) {
		errorMessage.value = $t('Ocurrió un error al reenviar el correo de verificación. Por favor, intenta de nuevo.')
	}

	if (response.success) {
		forwardedEmail.value = true
	}

	loadingSendEmail.value = false
}
</script>

<template>
	<div class="p-4">
		<UEmptyState
			:icon="
				forwardedEmail ? 'i-material-symbols-mark-email-read-outline' : 'i-material-symbols-mark-email-unread-outline'
			"
			color="bg-primary-500 text-primary-500"
			:title="$t('Valida tu correo electrónico')"
		>
			<div class="text-sm text-gray-800 space-y-4 my-4">
				<template v-if="forwardedEmail">
					<p>
						{{ $t('El correo de verificación ha sido reenviado con éxito. Por favor, revisa tu bandeja de entrada.') }}
					</p>
				</template>
				<template v-else>
					<p>
						{{
							$t(
								'Revisa tu bandeja de entrada para encontrar el correo de verificación que te enviamos al registrarte.',
							)
						}}
					</p>
					<p>
						{{ $t('Si no has recibido el código de verificación, haz clic en el botón de abajo para reenviarlo.') }}
					</p>
				</template>

				<UAlert
					v-if="errorMessage"
					icon="i-material-symbols-warning-outline-rounded"
					color="red"
					variant="soft"
					class="text-left"
					title="Error"
					:description="errorMessage"
				/>
			</div>

			<UButton
				v-if="!forwardedEmail"
				variant="ghost"
				size="lg"
				:loading="loadingSendEmail"
				@click="resendEmail"
			>
				{{ $t('Reenviar código') }}
			</UButton>
		</UEmptyState>
	</div>
</template>

<style scoped></style>
