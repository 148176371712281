<script setup lang="ts">
import { computed } from 'vue'

interface Slot {
	bswo_id: number
	id: number
	name: string
	expiration: string | null
}

const props = defineProps<{
	offerSlot: Slot
}>()

const COLOR_MAPPING = {
	'al éxito': 'blue',
	pro: 'purple',
	staffing: 'pink',
} as const

const badgeColor = computed(() => {
	const name = props.offerSlot.name.toLowerCase()
	return Object.entries(COLOR_MAPPING).find(([key]) => name.includes(key))?.[1] ?? 'gray'
})
</script>

<template>
	<UBadge
		class="px-2 py-1 text-sm"
		variant="soft"
		:color="badgeColor"
	>
		{{ offerSlot.name }}
	</UBadge>
</template>
