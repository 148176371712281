<script setup lang="ts">
import ModalToValidateEmail from '~/components/home/onboarding/ModalToValidateEmail.vue'
import ModalValidatedMail from '~/components/home/onboarding/ModalValidatedMail.vue'
import { useOnboardingStore } from '~/store/onboarding'

const router = useRouter()
const route = useRoute()
const { t: $t } = useI18n()
const { $tracking } = useNuxtApp()
const localePath = useLocalePath()
const { getProgress, getOnboarding } = storeToRefs(useOnboardingStore())

const showModalToValidateEmail = ref(false)
const showModalValidatedMail = ref(false)

const goToAction = (item) => {
	if (item.completed) {
		return null
	}

	if (item.type === 'modal') {
		showModalToValidateEmail.value = true
		return
	}

	router.push(item.to)
}

const isModalValidatedMail = () => {
	if (route.query?.email === 'true') {
		showModalValidatedMail.value = true
	}
}

const closeModalValidatedMail = () => {
	showModalValidatedMail.value = false

	router.replace({
		query: {
			...route.query,
			email: undefined,
		},
	})
}

onMounted(() => {
	isModalValidatedMail()
	$tracking.profile.onboardingComplete()
})
</script>

<template>
	<div
		v-if="getProgress !== 100"
		class="xl:col-span-6"
	>
		<UCard>
			<div class="space-y-4">
				<h2 class="text-xl font-semibold">{{ $t('Tu perfil') }}</h2>

				<UProgress
					:value="getProgress"
					size="xl"
				/>

				<div class="text-sm">
					<h3 class="">{{ $t('¡Bienvenido a bordo!') }}</h3>
					<p class="font-light text-neutral-500">
						{{ $t('Completa las siguientes tareas para configurar completamente tu cuenta') }}:
					</p>
				</div>

				<div class="text-sm font-light -mr-2">
					<ul class="space-y-4">
						<li
							v-for="(item, index) in getOnboarding"
							:key="index"
						>
							<a
								:href="localePath(item.to)"
								class="flex gap-2 group"
								:class="{
									'cursor-default': item.completed,
								}"
								@click.prevent="goToAction(item)"
							>
								<span
									class="border w-3.5 h-3.5 rounded-full flex-shrink-0 inline-flex items-center justify-center text-white"
									:class="item.completed ? 'border-green-400 bg-green-400' : 'border-gray-500'"
								>
									<UIcon name="i-heroicons-check" />
								</span>

								<span class="flex items-center flex-wrap gap-1 -mt-0.5">
									<span :class="item.completed ? 'line-through text-neutral-500' : ''">{{ item.label }}</span>

									<span
										class="capitalize font-medium"
										:class="{
											'text-green-400': item.completed,
											'text-primary-500 group-hover:underline': !item.completed,
										}"
									>
										{{ item.completed ? $t('Completado') : item.label_task }}
									</span>
								</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</UCard>
	</div>

	<UModal
		v-model="showModalToValidateEmail"
		title=""
		hidden-footer
	>
		<ModalToValidateEmail />
	</UModal>

	<UModal
		v-model="showModalValidatedMail"
		title=""
		hidden-footer
		@close="closeModalValidatedMail"
	>
		<ModalValidatedMail @close="closeModalValidatedMail" />
	</UModal>
</template>

<style scoped></style>
