<template>
	<UBadge
		:color="getColor"
		variant="soft"
	>
		<span class="uppercase flex items-center gap-1">
			<UIcon
				v-if="getIcon"
				:name="getIcon"
				size="16"
			/>
			<span>{{ label }}</span>
		</span>
	</UBadge>
</template>

<script setup lang="ts">
const props = defineProps<{
	id: number
	label: string
}>()

const getIcon = computed(() => {
	switch (props.id) {
		case ID_OFFER_PENDING_TO_APPROVED:
		case ID_OFFER_NEGOTIATION:
		case ID_OFFER_CONTENT_CURATION:
			return 'i-ic-outline-access-time-filled'
		case ID_OFFER_DRAFT:
		case ID_OFFER_DRAFT_HIRE:
			return 'i-ic-baseline-horizontal-rule'
		case ID_OFFER_APPROVED:
		case ID_OFFER_ACTIVE:
			return 'i-ic-baseline-play-arrow'
		case ID_OFFER_REJECTED:
			return 'i-ic-baseline-stop'
		case ID_OFFER_PAUSE:
			return 'i-ic-baseline-pause'
		default:
			return null
	}
})

const getColor = computed(() => {
	switch (props.id) {
		case ID_OFFER_PENDING_TO_APPROVED:
		case ID_OFFER_NEGOTIATION:
		case ID_OFFER_CONTENT_CURATION:
		case ID_OFFER_PAUSE:
			return 'yellow'
		case ID_OFFER_REJECTED:
			return 'red'
		case ID_OFFER_APPROVED:
		case ID_OFFER_ACTIVE:
			return 'green'
		default:
			return 'gray'
	}
})
</script>

<style lang="scss" scoped></style>
