<script setup lang="ts">
import ItemServiceFeatureDetail from '~/components/payments/pricing/ItemServiceFeatureDetail.vue'
import { useSupportStore } from '~/store/support'
import { usePlanDetails } from '~/composables/useSubscription'
import {
	ID_OFFER_ACTIVES,
	ID_COUNT_USERS,
	ID_COUNT_CANDIDATES,
	ID_COUNT_CANDIDATES_REJECTED,
	ID_COUNT_CANDIDATES_AI,
	ID_OFFER_DURATION,
	ID_SPECIALIST_RECOMMENDATION,
} from '~/utils/constants/planDetailsIds'
import { SQ3_FREE_LIMITS } from '~/config/featureFlags'

const { $subscription, $auth } = useNuxtApp()
const { t: $t } = useI18n()
const localePath = useLocalePath()
const { fetchSupport } = useSupportStore()
const { getNamePlan } = usePlanDetails()

const filterIds = [
	ID_OFFER_ACTIVES,
	ID_COUNT_USERS,
	ID_COUNT_CANDIDATES,
	ID_COUNT_CANDIDATES_REJECTED,
	ID_COUNT_CANDIDATES_AI,
	ID_OFFER_DURATION,
	ID_SPECIALIST_RECOMMENDATION,
]

const loadingData = ref(true)
const showDetails = ref(false)
const planRules = ref([])

const getProgress = computed(() => {
	const listRulesProgress = planRules.value.filter((rule) => rule.datatype === 'progress')

	const totalProgress = listRulesProgress.reduce((acc, rule) => acc + rule.used, 0)
	const totalValue = listRulesProgress.reduce((acc, rule) => acc + rule.value, 0)

	return Math.round((totalProgress / totalValue) * 100)
})

const getProgressMessage = computed(() => {
	return $t('Uso de tu plan {namePlan}', {
		namePlan: getNamePlan.value,
	})
})

const setLitePlan = () => {
	const rules = $subscription.getCurrentProduct.rules

	const filterRules = []

	filterIds.forEach((id) => {
		const rule = rules.find((rule) => rule.id === id)
		if (!rule) return

		const item = { ...rule }

		if (rule.id === ID_OFFER_ACTIVES) {
			item.datatype = 'progress'
			item.value = $auth.user.business.rules.max_work_offers_allowed
			item.used = $auth.user.offers.active_count
		}

		if (rule.datatype === 'int' && rule.id === ID_COUNT_USERS) {
			item.datatype = 'progress'
			item.value = Number(item.value)
			item.used = $auth.user.business.users_qty
		}

		filterRules.push(item)
	})

	planRules.value = filterRules
}

const fetchPlan = async () => {
	await Promise.allSettled([fetchSupport(['subscriptions']), $subscription.fetchCurrentSubscription()])
}

onMounted(async () => {
	await fetchPlan()
	setLitePlan()
	loadingData.value = false
})
</script>

<template>
	<UCard
		:ui="{ background: 'bg-neutral-700' }"
		class="text-white"
	>
		<div class="space-y-5">
			<template v-if="loadingData">
				<h2 class="text-xl font-semibold">
					<USkeleton
						class="w-4/12"
						:ui="{ base: 'h-7' }"
					/>
				</h2>
				<div class="flex items-center gap-4 py-[2px]">
					<USkeleton
						class="w-6/12"
						:ui="{ rounded: 'rounded-full' }"
					/>
				</div>
			</template>

			<template v-else>
				<div>
					<h2 class="text-xl font-semibold">Plan {{ getNamePlan }}</h2>
					<p
						v-if="$hasFeature(SQ3_FREE_LIMITS) && $subscription.isSubscriptionFree"
						class="text-sm text-neutral-500"
					>
						{{
							$t(
								'¡Estamos mejorando la plataforma para ti! Disfruta de más funciones sin límites en tu plan Free por tiempo limitado.',
							)
						}}
					</p>
				</div>
				<div class="flex items-center flex-wrap gap-4">
					<div class="w-24">
						<UProgress
							:value="getProgress"
							size="xl"
							color="green"
						/>
					</div>

					<span class="flex items-center gap-1.5 text-neutral-400">
						<b class="font-semibold">{{ getProgress }}%</b>
						<span class="size-1 bg-neutral-400 rounded-full"></span>
						<span class="font-light text-sm">{{ getProgressMessage }} </span>
					</span>
				</div>
			</template>

			<UCollapse>
				<div v-if="showDetails">
					<div class="divide-y divide-neutral-600 py-4">
						<div
							v-for="item in planRules"
							:key="item.id"
							class="py-2.5"
						>
							<ItemServiceFeatureDetail
								:item="item"
								class-description="xs:text-sm"
								class-value="text-xs font-light text-neutral-100"
								class=""
							/>
						</div>
					</div>
				</div>
			</UCollapse>

			<div class="grid grid-cols-2 gap-6">
				<UButton
					size="sm"
					variant="soft"
					color="white"
					:icon="showDetails ? 'i-ic-baseline-minus' : 'i-material-symbols-add'"
					class="justify-between text-white bg-white bg-opacity-10 hover:bg-opacity-15"
					trailing
					@click="showDetails = !showDetails"
				>
					{{ showDetails ? $t('Menos información') : $t('Más información') }}
				</UButton>

				<UButton
					size="sm"
					color="white"
					class="text-primary-500 hover:bg-primary-500 hover:text-white hover:ring-primary-500"
					block
					:to="localePath('/subscription/update')"
					@click="$tracking.upselling.upgradeSubscriptionHomeClick()"
				>
					<span>{{ $t('Actualizar plan') }}</span>
				</UButton>
			</div>
		</div>
	</UCard>
</template>

<style scoped></style>
