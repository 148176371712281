<script setup lang="ts">
import { SQUAD_04_20241128_PLAN_SELECT_HIRE_REDESIGN_HOME } from '~/config/featureFlags'

const { $hasFeature } = useNuxtApp()
</script>

<template>
	<NuxtLinkLocale
		to="/offer/import"
		class="group border-2 border-neutral-100 hover:border-primary-500 border-dashed bg-primary-100 bg-opacity-5 hover:bg-opacity-20 h-full rounded-2xl flex items-center justify-center transition-colors min-h-[252px]"
	>
		<div class="items-center flex flex-col gap-5 -mt-6">
			<img
				:src="`${$config.public.cdn}/empty-search_1721369538664.svg`"
				alt="Search"
				class="size-28"
			/>

			<span
				class="text-sm text-neutral-600 group-hover:text-primary-700 transition-colors inline-flex items-center gap-1"
			>
				<UIcon name="i-material-symbols-add" />
				<span>{{
					$hasFeature(SQUAD_04_20241128_PLAN_SELECT_HIRE_REDESIGN_HOME)
						? $t('Crear oferta')
						: $t('Crear nueva búsqueda')
				}}</span>
			</span>
		</div>
	</NuxtLinkLocale>
</template>
