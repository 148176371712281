<script setup lang="ts"></script>

<template>
	<UCard
		:ui="{ body: { base: 'h-full' } }"
		class="h-full min-h-[296px]"
	>
		<div class="flex flex-col justify-between h-full gap-3">
			<header class="space-y-3">
				<ul class="flex items-center flex-wrap gap-1">
					<li class="flex-1">
						<USkeleton
							:ui="{ base: 'h-6', rounded: 'rounded-full' }"
							class="w-4/12"
						/>
					</li>
				</ul>

				<h3 class="font-semibold"><USkeleton class="w-6/12" /></h3>
			</header>

			<div class="space-y-4">
				<ul class="text-xs space-y-3">
					<li class="flex gap-2">
						<USkeleton class="w-3" />
						<USkeleton class="w-7/12" />
					</li>
					<li class="flex gap-2">
						<USkeleton class="w-3" />
						<USkeleton class="w-4/12" />
					</li>
					<li class="flex gap-2">
						<USkeleton class="w-3" />
						<USkeleton class="w-6/12" />
					</li>
				</ul>
			</div>

			<footer class="mt-auto pt-2">
				<span class="text-sm font-medium">
					<USkeleton class="w-4/12" />
				</span>
			</footer>
		</div>
	</UCard>
</template>

<style scoped></style>
