<script setup lang="ts">
const { t: $t } = useI18n()

const emit = defineEmits(['close'])
</script>

<template>
	<div class="p-4">
		<UEmptyState
			icon="i-material-symbols-mark-email-read-outline"
			color="bg-green-500 text-green-400"
			:title="$t('¡Correo verificado!')"
		>
			<div class="text-sm text-gray-800 mt-3 mb-5">
				<p>
					{{ $t('Hemos verificado tu dirección de correo electrónico. Gracias por ayudarnos a proteger tu cuenta.') }}
				</p>
			</div>

			<UButton
				size="lg"
				@click="emit('close')"
			>
				{{ $t('Entendido') }}
			</UButton>
		</UEmptyState>
	</div>
</template>

<style scoped></style>
