<script setup lang="ts">
import { useOffersStore } from '~/store/offers'
import UserName from '~/components/users/UserName.vue'
import CardOffer from '~/components/offer/CardOffer.vue'
import SkeletonCardOffer from '~/components/offer/SkeletonCardOffer.vue'

const EmptyStateOffers = defineAsyncComponent(() => import('~/components/home/EmptyStateOffers.vue'))
const CardOfferAdd = defineAsyncComponent(() => import('~/components/offer/CardOfferAdd.vue'))
const CardWithOutOffersAvailable = defineAsyncComponent(
	() => import('~/components/offers/CardWithOutOffersAvailable.vue'),
)

const { fetchOffers, defaultStatusSlugs } = useOffersStore()
const { offers } = storeToRefs(useOffersStore())
const router = useRouter()
const route = useRoute()
const { t: $t } = useI18n()
const { $auth } = useNuxtApp()

const loadingOffers = ref(true)

const canCreateOffer = computed<boolean>(() => {
	return $auth.user.business.rules.can_create_work_offers
})

const fetchData = async (params) => {
	loadingOffers.value = true
	const { status_slugs } = params

	if (status_slugs) {
		params.status_slugs = Array.isArray(status_slugs) ? status_slugs : [status_slugs]
	} else {
		params.status_slugs = defaultStatusSlugs
	}

	await fetchOffers(params)
	loadingOffers.value = false
}

const changePage = (page) => {
	const query = { ...route.query, page }
	router.push({ query })
}

watch(
	() => route.query,
	(newQuery, oldQuery) => {
		if (newQuery.page !== oldQuery.page || newQuery.status_slugs !== oldQuery.status_slugs) {
			fetchData(newQuery)
		}
	},
)

onMounted(() => {
	fetchData(route.query)
})
</script>

<template>
	<EmptyStateOffers v-if="offers.length === 0" />
	<div v-else>
		<UContainer>
			<UserName
				class="text-2xl font-bold mb-6"
				:prefix="$t('Bienvenido')"
				icon="👋"
				icon-position="after"
				tag="h1"
				:full-name="false"
			/>

			<div class="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
				<template v-if="loadingOffers">
					<SkeletonCardOffer
						v-for="item in 4"
						:key="item"
					/>
				</template>
				<template v-else>
					<CardOffer
						v-for="offer in offers.data"
						:key="offer.id"
						:offer="offer"
					/>
					<CardOfferAdd v-if="canCreateOffer" />
					<CardWithOutOffersAvailable v-else />
				</template>
			</div>

			<UPagination
				v-if="offers.pagination.total_pages > 1"
				v-model="offers.pagination.current_page"
				class="justify-center mt-6"
				:total="offers.pagination.total"
				:page-count="offers.pagination.per_page"
				@update:model-value="changePage"
			/>
		</UContainer>
	</div>
</template>
