<script setup lang="ts">
import StatusPillOffer from '~/components/offer/StatusPillOffer.vue'
import OptionsOffer from '~/components/offer/OptionsOffer.vue'
import PillOfferSlot from '~/components/offer/PillOfferSlot.vue'
import { getDaysRemaining } from '~/utils/dates'

const props = defineProps({
	offer: {
		type: Object,
		required: true,
	},
})

const { locale, t: $t } = useI18n()
const { $date } = useNuxtApp()

const isDraft = computed(() => [ID_OFFER_DRAFT_HIRE, ID_OFFER_DRAFT].includes(props.offer.work_offer_status.id))

const getLink = computed(() => (isDraft.value ? `/offers/${props.offer.id}/edit` : `/offers/${props.offer.id}`))

const formatDate = (date: string): string => {
	if (locale.value === 'en') {
		return $date(date).format('MM/DD/YYYY')
	}

	return $date(date).format('DD/MM/YYYY')
}

const isPendingOrNegotiationOrRejected = computed<boolean>(() =>
	[ID_OFFER_PENDING_TO_APPROVED, ID_OFFER_NEGOTIATION, ID_OFFER_REJECTED].includes(props.offer.work_offer_status.id),
)

const EXPIRATION_WARNING_DAYS = 7

const daysRemaining = computed(() => {
	if (!props.offer.slot?.expiration) return 0
	return getDaysRemaining(props.offer.slot.expiration)
})

const shouldShowExpirationWarning = computed(() => {
	return (
		!isPendingOrNegotiationOrRejected.value &&
		!isDraft.value &&
		props.offer.slot?.expiration &&
		daysRemaining.value <= EXPIRATION_WARNING_DAYS
	)
})
</script>

<template>
	<UCard
		class="group transition-shadow hover:ring-2 ring-primary-300 relative"
		:ui="{ divide: 'px-6', body: { padding: '!px-0' }, footer: { padding: '!px-0' } }"
	>
		<div class="flex items-center justify-between gap-2 mb-3">
			<div class="space-x-2">
				<StatusPillOffer
					:id="offer.work_offer_status.id"
					:label="offer.work_offer_status.label"
				/>
				<PillOfferSlot
					v-if="offer.slot"
					:offer-slot="offer.slot"
				/>
			</div>
			<div class="relative z-10">
				<div class="bg-gray-50 rounded-full">
					<OptionsOffer :offer="props.offer" />
				</div>
			</div>
		</div>

		<h3 class="text-xl font-bold mb-4">{{ offer.name }}</h3>

		<div class="space-y-2 mb-4">
			<div class="flex items-center gap-2">
				<span class="bg-gray-50 rounded-full p-1 flex items-center">
					<UIcon
						name="i-heroicons-calendar"
						class="text-gray-600 font-bold"
					/>
				</span>
				<span class="text-sm text-gray-600">
					<strong>{{ isPendingOrNegotiationOrRejected ? $t('Enviada') : $t('Publicada') }}: </strong>
					<span v-if="isDraft">--</span>
					<span v-else>{{ formatDate(isPendingOrNegotiationOrRejected ? offer.send_at : offer.published_at) }}</span>
				</span>
			</div>
			<div class="flex items-center gap-2">
				<span class="bg-gray-50 rounded-full p-1 flex items-center">
					<UIcon
						name="i-heroicons-calendar"
						class="text-gray-600 font-bold"
					/>
				</span>
				<span class="text-sm text-gray-600">
					<strong>{{ $t('Expira') }}: </strong>
					<span v-if="isDraft">--</span>
					<span v-else>
						<template v-if="isPendingOrNegotiationOrRejected"> {{ $t('Pendiente de validación') }} </template>
						<template v-else-if="offer.slot">
							{{ offer.slot.expiration ? formatDate(offer.slot.expiration) : $t('Ilimitada') }}
						</template>
						<template v-else> {{ $t('--') }} </template>
					</span>
				</span>
				<span
					v-if="shouldShowExpirationWarning"
					class="px-2 py-1 bg-red-100 text-red-700 rounded-full text-sm"
					v-html="
						$t('offer.card.ends_in', {
							days: daysRemaining,
						})
					"
				/>
			</div>
		</div>

		<template #footer>
			<div class="grid grid-cols-2 gap-4">
				<div class="border-r">
					<span class="font-bold">{{ offer.to_review_count }}</span>
					<div class="text-sm text-gray-600">{{ $t('Talentos por revisar') }}</div>
				</div>
				<div>
					<div class="font-bold">{{ offer.inprocess_count }}</div>
					<div class="text-sm text-gray-600">{{ $t('Candidatos en pipeline') }}</div>
				</div>
			</div>
		</template>

		<NuxtLinkLocale
			:to="getLink"
			class="absolute inset-0 z-0"
		/>
	</UCard>
</template>
