<script setup>
import PageHomeOld from '~/components/home/PageHomeOld.vue'
import { SQUAD_04_20241128_PLAN_SELECT_HIRE_REDESIGN_HOME } from '~/config/featureFlags.js'
import PageHome from '~/components/home/PageHome.vue'

const { $hasFeature } = useNuxtApp()
</script>

<template>
	<PageHomeOld v-if="!$hasFeature(SQUAD_04_20241128_PLAN_SELECT_HIRE_REDESIGN_HOME)" />
	<PageHome v-else />
</template>
