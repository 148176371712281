<script setup lang="ts">
import StatusPillOfferOld from '~/components/offer/StatusPillOfferOld.vue'
import OptionsOffer from '~/components/offer/OptionsOffer.vue'
import { MINI_FEATURES_FOR_MY_OFFERS } from '~/config/featureFlags.js'

const props = defineProps({
	offer: {
		type: Object,
		required: true,
	},
})

const { $date, $subscription } = useNuxtApp()
const localePath = useLocalePath()
const { t: $t } = useI18n()

const showMessageUpdate = ref(false)

const isDraft = computed(() => [ID_OFFER_DRAFT_HIRE, ID_OFFER_DRAFT].includes(props.offer.work_offer_status.id))

const getLink = computed(() => (isDraft.value ? `/offers/${props.offer.id}/edit` : `/offers/${props.offer.id}`))

const highlightCandidatesToReview = computed(
	() =>
		![ID_OFFER_INACTIVE, ID_OFFER_DISABLED, ID_OFFER_CLOSE, ID_OFFER_REJECTED].includes(
			props.offer.work_offer_status.id,
		),
)

const getDates = computed(() => {
	const offerStatusId = props.offer.work_offer_status.id

	if ([ID_OFFER_REJECTED].includes(offerStatusId)) {
		return [
			{
				label: $t('Rechazado'),
				value: $date(props.offer.closed_at).format('DD/MM/YYYY'),
			},
			{
				label: $t('Enviado'),
				value: $date(props.offer.sended_at).format('DD/MM/YYYY'),
			},
		]
	}

	if ([ID_OFFER_ACTIVE].includes(offerStatusId)) {
		return [
			{
				label: $t('Publicación'),
				value: $date(props.offer.published_at).format('DD/MM/YYYY'),
			},
			{
				label: $t('Enviado'),
				value: $date(props.offer.sended_at).format('DD/MM/YYYY'),
			},
		]
	}

	if ([ID_OFFER_PENDING_TO_APPROVED, ID_OFFER_NEGOTIATION].includes(offerStatusId)) {
		return [
			{
				label: $t('Enviado'),
				value: $date(props.offer.sended_at).format('DD/MM/YYYY'),
			},
			{
				label: $t('Última actualización'),
				value: $date(props.offer.updated_at).format('DD/MM/YYYY'),
			},
		]
	}

	return [
		{
			label: $t('Última actualización'),
			value: $date(props.offer.updated_at).format('DD/MM/YYYY'),
		},
		{
			label: $t('Creado'),
			value: $date(props.offer.created_at).format('DD/MM/YYYY'),
		},
	]
})

onMounted(() => {
	if ($subscription.isSubscriptionFree && props.offer.is_expired) {
		setTimeout(() => {
			showMessageUpdate.value = true
		}, 200)
	}
})
</script>

<template>
	<UCard
		:ui="{ body: { base: 'h-full' } }"
		class="h-full group transition-shadow ring-primary-300 relative overflow-hidden"
		:class="!showMessageUpdate && 'hover:ring-2'"
	>
		<div class="flex flex-col justify-between h-full gap-3">
			<header class="space-y-3">
				<ul class="flex items-center flex-wrap gap-1">
					<li :class="{ 'flex justify-between w-full items-center': $hasFeature(MINI_FEATURES_FOR_MY_OFFERS) }">
						<StatusPillOfferOld
							:id="props.offer.work_offer_status.id"
							:label="props.offer.work_offer_status.label"
						/>
						<div class="relative z-10">
							<OptionsOffer
								v-if="$hasFeature(MINI_FEATURES_FOR_MY_OFFERS)"
								:offer="props.offer"
							/>
						</div>
					</li>
				</ul>

				<h3 class="font-semibold">{{ props.offer.name }}</h3>
			</header>

			<div class="space-y-4">
				<ul class="text-xs space-y-2 text-neutral-600">
					<li class="flex gap-2">
						<span class="flex-shrink-0">
							<UIcon
								name="ic:twotone-calendar-today"
								dynamic
								size="12"
							/>
						</span>

						<ul class="space-y-2 flex-1">
							<li
								v-for="(date, i) in getDates"
								:key="i"
							>
								<span class="block">
									{{ date.label }}:
									<b class="font-medium">{{ date.value }}</b>
								</span>
							</li>
						</ul>
					</li>

					<li
						v-if="props.offer.to_review_count"
						class="flex gap-2"
						:class="highlightCandidatesToReview && 'text-blue-500'"
					>
						<span class="flex-shrink-0 relative">
							<UIcon
								name="i-ic-baseline-person-add-alt"
								size="12"
							/>
							<span
								v-if="highlightCandidatesToReview"
								class="absolute inset-0 flex h-3.5 w-3.5 -ml-0.5"
							>
								<span
									class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-500 opacity-20"
								></span>
							</span>
						</span>
						<span class="flex-1">
							{{
								$t(
									'{count} Candidato por revisar | {count} Candidatos por revisar',
									{
										count: props.offer.to_review_count,
									},
									props.offer.to_review_count,
								)
							}}
						</span>
					</li>

					<li class="flex gap-2">
						<span class="flex-shrink-0 relative">
							<UIcon
								name="i-ic-twotone-supervisor-account"
								size="12"
							/>
						</span>
						<span class="flex-1">
							{{
								$t(
									'{count} Referido | {count} Referidos',
									{ count: props.offer.refered_count },
									props.offer.refered_count,
								)
							}}
							|
							{{ $t('{count} En proceso', { count: props.offer.inprocess_count }, props.offer.inprocess_count) }}
						</span>
					</li>

					<li class="flex gap-2">
						<span class="flex-shrink-0">
							<UIcon
								name="i-ic-outline-check"
								size="12"
							/>
						</span>
						<span class="flex-1">
							{{
								$t(
									'{count} Contratado | {count} Contratados',
									{ count: props.offer.hired_count },
									props.offer.hired_count,
								)
							}}
						</span>
					</li>
				</ul>
			</div>

			<footer class="mt-auto pt-2">
				<NuxtLinkLocale :to="getLink">
					<span class="text-sm font-medium text-primary-500 flex items-center">
						{{ isDraft ? $t('Seguir editando') : $t('Ver candidatos') }}
						<UIcon
							class="transition-transform group-hover:translate-x-0.5 ml-1.5"
							name="i-ic-baseline-arrow-forward"
						/>
					</span>
				</NuxtLinkLocale>
			</footer>
		</div>

		<NuxtLinkLocale
			:to="getLink"
			class="absolute inset-0 z-0"
		/>

		<transition name="overlay-reactive-offer">
			<div
				v-if="showMessageUpdate"
				class="bg-neutral-700 bg-opacity-50 absolute inset-0 z-30"
			/>
		</transition>

		<transition name="message-reactive-offer">
			<div
				v-if="showMessageUpdate"
				class="absolute bottom-0 left-0 w-full bg-white p-4 rounded-t-2xl z-30"
			>
				<div class="flex items-center justify-between mb-2">
					<h3 class="font-semibold text-sm">{{ $t('Reactivar tu oferta') }}</h3>
					<UButton
						icon="i-ic-outline-close"
						color="black"
						variant="ghost"
						@click="showMessageUpdate = false"
					/>
				</div>

				<p class="text-xs mb-3 text-gray-600">
					{{
						$t(
							'Tu oferta ha alcanzado el límite de tiempo del período gratuito. Puedes reactivarla adquiriendo una suscripción.',
						)
					}}
				</p>

				<UButton
					variant="outline"
					:to="localePath('/subscription/buy')"
					@click="$tracking.upselling.upgradeSubscriptionOfferDisabledClick()"
				>
					{{ $t('Mejorar mi plan') }}
				</UButton>
			</div>
		</transition>
	</UCard>
</template>

<style scoped>
.overlay-reactive-offer-enter-active,
.overlay-reactive-offer-leave-active,
.message-reactive-offer-enter-active,
.message-reactive-offer-leave-active {
	transition: all 0.3s;
}

.overlay-reactive-offer-enter-from,
.overlay-reactive-offer-leave-to {
	opacity: 0;
}

.message-reactive-offer-enter-from,
.message-reactive-offer-leave-to {
	transform: translateY(100%);
}
</style>
